import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { changeLocale, IntlContextConsumer, injectIntl } from "gatsby-plugin-intl"

import CategoryDropdown from "../components/categorydropdown"

const languageName = {
  en: "English",
  es: "Español",
}

const Header = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query CategoryQuery {
      Courts {
        offense_categories:analysis_category_counts_all_time(
          order_by: {offense_category: asc},
          where: {charges: {_gte: "750"}}
        ) {
          offenseCategoryByOffenseCategory {
            category_slug
            offense_category
            offense_category_es
          }
        }
      }
    }
  `)

  return (
    <header className="header">
      <a href="https://thecircuit.cc/" className="nav-icon">
        <img alt="The Circuit logo" src={require('../images/circuit_block.png')} />
        <span className="navbar-title">
          <span className="lightWeight">
            {intl.formatMessage({
              id: "the",
              defaultMessage: "The"
            })}
          </span>
          {intl.formatMessage({
            id: "circuit",
            defaultMessage: "Circuit"
          })}
        </span>
      </a>
      <input className="menu-btn" type="checkbox" id="menu-btn" />
      <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
      <ul className="menu">
        <li>
          <a href="/">
            {intl.formatMessage({
              id: "home",
              defaultMessage: "Home"
            })}
          </a>
        </li>
        <li>
          <a href="/methodology">
            {intl.formatMessage({
              id: "methodology",
              defaultMessage: "Methodology"
            })}
          </a>
        </li>
        <li>
          <a href="https://thecircuit.cc/">
            {intl.formatMessage({
              id: "read_the_stories",
              defaultMessage: "Read the Stories"
            })}
          </a>
        </li>
        <li>
          <IntlContextConsumer>
            {({ languages, language: currentLocale }) =>
              languages.map((language, index) => (
                <>
                  <a
                    key={language}
                    onClick={() => changeLocale(language)}
                    className={currentLocale === language ? `active` : null}
                    style={{ cursor: 'pointer' }}
                  >
                    {languageName[language]}
                  </a>
                  {(index ? '' : <strong> / </strong>)}
                </>
              ))
            }
          </IntlContextConsumer>
        </li>
      </ul>
      <CategoryDropdown
        offenseCategories={data.Courts.offense_categories}
        intl={intl}
      />
    </header>
  )
}

export default injectIntl(Header)
