import React from "react"
import { navigate } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { capfirst } from "journalize"

const CategoryDropdown = (props) => {
  const intl = props.intl

  return (
    <div>
      <label>
        {intl.formatMessage(
          {
            id: "headers.jumpto",
            defaultMessage: "Jump to"
          },
        )}
        <select
          onChange={e => {console.log(e.target.value); navigate(`/${e.target.value}`)}}
        >
          <option>
            {intl.formatMessage({
              id: "headers.selectcategory",
              defaultMessage: "Select an offense category"
            })}
          </option>
          {props.offenseCategories.map((category, i) =>
            <option key={`chargeoption${i}`} value={
              category.offenseCategoryByOffenseCategory.category_slug
            }>
              {intl.formatMessage(
                {
                  id: "offense_categories.name",
                  defaultMessage: capfirst(category.offense_category)
                },
                {
                  offense_category: capfirst(category.offenseCategoryByOffenseCategory.offense_category),
                  offense_category_es: capfirst(category.offenseCategoryByOffenseCategory.offense_category_es)
                }
              )}
            </option>
          )}
        </select>
      </label>
    </div>
  )
}

export default injectIntl(CategoryDropdown)
